/* import __COLOCATED_TEMPLATE__ from './render-paywall.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';

export interface Args {
  model: {
    planId: string;
    launchPaywall: boolean;
    featureKey: string;
    upgradePlanId: string;
    showUpgradePathPlans: boolean;
    afterCloseModal?: () => void;
    afterUpgradeModalFailedToOpen?: () => void;
    showExperimentalPaywall: boolean;
    featureLessHeaders: boolean;
    onActionComplete?: (options?: { success: boolean }) => void;
  };
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class RenderPaywall extends Component<Signature> {
  get showExperimentalPaywall(): boolean {
    return this.args.model.showExperimentalPaywall;
  }

  get launchPaywall(): boolean {
    return this.args.model.launchPaywall;
  }

  get featureKey(): string {
    return this.args.model.featureKey;
  }

  get planId(): string {
    return this.args.model.planId;
  }

  get upgradePlanId(): string {
    return this.args.model.upgradePlanId;
  }

  get showUpgradePathPlans(): boolean {
    return this.args.model.showUpgradePathPlans;
  }

  get afterCloseUpgradeModal(): () => void {
    return this.args.model.afterCloseModal ?? (() => {});
  }

  get onActionComplete(): ((options?: { success: boolean }) => void) | undefined {
    return this.args.model.onActionComplete;
  }

  get afterUpgradeModalFailedToOpen(): () => void {
    return this.args.model.afterUpgradeModalFailedToOpen ?? (() => {});
  }

  get featureLessHeaders(): boolean {
    return this.args.model.featureLessHeaders;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::RenderPaywall': typeof RenderPaywall;
    'paywalls/render-paywall': typeof RenderPaywall;
  }
}
