/* import __COLOCATED_TEMPLATE__ from './paywall.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { enqueueTask, dropTask } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import {
  PLAN_DATA,
  CORE_STARTER_BASE_ID,
  CORE_ENGAGE_PRO_ID,
  VBP2_PLAN_IDS,
  INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import { captureException } from 'embercom/lib/sentry';

export default class Paywall extends Component {
  @service appService;
  @service customerService;
  @service quoteService;
  @service paywallService;
  @service modalService;
  @service stripev3;
  @service annualSubscriptionUpdateService;
  @service purchaseAnalyticsService;
  @service intl;
  @service notificationsService;
  @service modelDataCacheService;
  @service store;
  @service router;
  @service inboxVersion;
  @service catalogApi;
  @service selfServeTrialsService;
  @service intercomEventService;
  @service cardlessTrialService;
  @service cardlessConversionModalService;
  @tracked showUpgradeModal = false;
  @tracked requiredAdditionalPlanIds = [];
  @tracked fakePlan;
  @tracked upgrading;
  @tracked showFinStandaloneUpgradeModal = false;

  constructor() {
    super(...arguments);
    if (this.args.launchModal) {
      this.openUpgradeModal();
    }
  }

  get app() {
    return this.appService.app;
  }

  get canViewComparisonPaywalls() {
    return this.selfServeTrialsService.canViewComparisonPaywalls(this.plan?.id);
  }

  get canTrialCorePlan() {
    return this.selfServeTrialsService.canTrialCorePlan(this.plan?.id);
  }

  get isCopilot() {
    return this.plan.id === FIN_AI_COPILOT_BASE_ID;
  }

  get showComparisonPaywall() {
    // We are branching for copilot to show the comparison style paywall for all pricing models
    // The thinking here is to slowly move all plans and pricing models to the newer style paywalls
    if (this.isCopilot) {
      return true;
    }
    return (
      (!this.app.isSalesforceContracted &&
        (this.customerService.onSelfServeVBP1Plan || this.customerService.onSelfServeVBP2Plan) &&
        this.canViewComparisonPaywalls) ||
      this.app.onPricing5
    );
  }

  get pricingMetric() {
    return this.args.pricingMetric;
  }

  get providedPlanId() {
    return this.args.upgradePlanId;
  }

  get analyticsEventData() {
    return {
      plan: this.plan?.id,
      feature: this.featureKey,
      productId: this.plan?.product?.id,
      productName: this.plan?.product?.key,
      ...this.analyticsMetadata,
    };
  }

  get activeProducts() {
    return this.app?.products.filter((product) => product.active);
  }

  get plans() {
    return this.app?.products.reduce(
      (previous, product) => previous.concat(product.plans.toArray()),
      [],
    );
  }

  get activePlans() {
    return this.activeProducts.map((product) => product.plans.find((plan) => plan.active));
  }

  get activePlanIds() {
    return this.activePlans.map((plan) => plan.id);
  }

  get activeNonGraduatingPlanTrialIds() {
    return this.customerService.activePlanTrials
      .filter((plan) => !plan.activeTrialIsGraduating)
      .mapBy('idAsNumber');
  }

  get activePlansUsageLimit() {
    let maximumsForActivePlans = this.activePlans
      .map((plan) => this.pricingStrategyFromPlanForMetric(plan)?.maximum)
      .compact();
    if (isPresent(maximumsForActivePlans)) {
      return Math.max(...maximumsForActivePlans);
    }
    return null;
  }

  get requiredPlanIdsAsStrings() {
    return this.requiredAdditionalPlanIds?.map((id) => id.toString()) || [];
  }

  get planText() {
    if (!this.canTrialCorePlan) {
      return this.plansWithFeature?.mapBy('nameWithProduct').join(' or ');
    }
  }

  get isMetricPaywall() {
    return isPresent(this.pricingMetric);
  }

  get isActive() {
    if (this.args.showUpgradePathPlans) {
      return true;
    }
    if (this.args.isActiveOverride !== undefined) {
      return this.args.isActiveOverride;
    }

    return (
      (this.featureKey || this.isMetricPaywall) &&
      this.notForcedInactive &&
      (this.appDoesNotHaveFeature || this.usageLimitReached)
    );
  }

  get isFrozenForNonPayment() {
    return this.app.isFrozenForNonPayment;
  }

  get canSelfServeTrials() {
    return (
      (this.plansWithFeature.length === 1 &&
        PLAN_DATA[this.plansWithFeature.firstObject.id]?.selfTrialAvailableOnContract &&
        this.selfServeTrialsService.canTrialSpecificProduct(this.plan?.id)) ||
      (this.canTrialCorePlan && this.plan?.selfServeTrialable)
    );
  }

  get addonName() {
    let firstPlanData =
      this.plansWithFeature.firstObject && PLAN_DATA[this.plansWithFeature.firstObject.id];
    if (firstPlanData) {
      let marketingNameTranslationKey = firstPlanData.marketingNameTranslationKey;
      if (marketingNameTranslationKey) {
        return this.intl.t(marketingNameTranslationKey);
      } else {
        return firstPlanData.marketingName;
      }
    }
  }

  get appDoesNotHaveFeature() {
    if (this.isMetricPaywall) {
      return false;
    }
    return !this.app?.canUseFeature(this.args.featureKey);
  }

  get isInactive() {
    return !this.isActive;
  }

  get notForcedInactive() {
    return !this.args.forceInactive;
  }

  get featureKey() {
    return this.args.featureKey;
  }

  get plansWithMetricUsageAvailable() {
    return this.plans.filter(
      (plan) => this.pricingStrategyFromPlanForMetric(plan)?.maximum > this.featureUsage,
    );
  }

  get plansWithFeature() {
    if (this.isMetricPaywall && this.isOnVBP2Starter) {
      return this.starterVBP2ToSupportUpgrade;
    } else if (this.isMetricPaywall) {
      return this.plansWithMetricUsageAvailable;
    } else if (this.providedPlanId) {
      return [this.plans.findBy('id', this.providedPlanId)].compact();
    }
    return this.app?.products
      .map((product) =>
        product.plans.find((plan) =>
          plan.features.any((feature) => feature.key === this.featureKey),
        ),
      )
      .compact();
  }

  get plan() {
    // upgradePlanId is used in the billing details page to mitigate
    // https://github.com/intercom/embercom/pull/52833.
    // It's also used in Intershop, where we provide the planId directly
    if (this.providedPlanId) {
      return this.plans.findBy('id', this.providedPlanId);
    }
    return this.plansWithFeature && this.plansWithFeature[this.preferredPlanIndex];
  }

  get preferredPlanIndex() {
    let planIds = this.plansWithFeature?.mapBy('id');
    let index = 0;

    for (let i = 0; i < this.planUpgradePath.length; i++) {
      if (planIds.includes(this.planUpgradePath[i])) {
        index = planIds.indexOf(this.planUpgradePath[i]);
        break;
      }
    }

    return index;
  }

  get isOnVBP2Starter() {
    return this.activePlanIds?.includes(CORE_STARTER_BASE_ID);
  }

  get starterVBP2ToSupportUpgrade() {
    // Hack to show the generic triple plan paywall for VBP2 Starter when they hit seat limits
    // Doesn't actually matter what "sales-led" plan we use as it's a generic paywall
    // Support Pro will set show the non graduating trials paywalls though
    return this.plans.filter((plan) => plan.id === CORE_ENGAGE_PRO_ID);
  }

  get planUpgradePath() {
    let planId = this.activePlanIds.filter((element) =>
      VBP2_PLAN_IDS.includes(element),
    ).firstObject;

    if (planId) {
      return PLAN_DATA[planId].upgradePath;
    } else {
      return [];
    }
  }

  get feature() {
    if (this.isMetricPaywall) {
      return { key: 'teammates', name: 'Teammates' };
    }
    if (!this.plansWithFeature.length) {
      return;
    }
    return this.plansWithFeature.firstObject.features.find(
      (feature) => feature.key === this.featureKey,
    );
  }

  get analyticsMetadata() {
    return this.args.analyticsMetadata || {};
  }

  get modalIsLoading() {
    return this.openModalAndSetData.isRunning;
  }

  get featureUsage() {
    return this.args.featureUsage || 0;
  }

  get appIsOnFree() {
    return this.app?.canUseFree;
  }

  get usageLimitReached() {
    if (!this.usageLimit || !this.featureUsage) {
      return false;
    }
    return this.featureUsage > this.usageLimit;
  }

  get isPricedOnSeats() {
    return (
      (this.pricingMetric === 'inbox_seats' &&
        this.app.activePricingMetrics.includes('inbox_seats')) ||
      (this.pricingMetric === 'latest_daily_admin_count' &&
        this.app.activePricingMetrics.includes('latest_daily_admin_count'))
    );
  }

  get contractedUsageLimit() {
    if (
      !this.app.hasSeatOveragesEnabled &&
      this.isPricedOnSeats &&
      this.app.isSalesforceContracted
    ) {
      return this.customerService.customer.freeSeatCount;
    }
  }

  get usageLimit() {
    if (this.isMetricPaywall) {
      // the usage limit in the customer's contract should
      // always take precence over the limit in the active plan
      if (this.contractedUsageLimit) {
        return this.contractedUsageLimit;
      }

      if (this.activePlansUsageLimit) {
        return this.activePlansUsageLimit;
      }
    }

    if (this.args.usageLimit) {
      return this.args.usageLimit;
    }

    // App feature is a feature model, while `this.feature` is a billing-feature...
    //  and billing-feature(s) do not have limits or limited
    let appFeature = this.app?.features.find((feature) => feature.id === this.featureKey);
    if (this.appIsOnFree && appFeature?.limited) {
      return appFeature.limit;
    }
    return null;
  }

  get disableFeatureHighlighting() {
    return this.args.disableFeatureHighlighting || false;
  }

  get allRequiredPlanIdsWithNewPlan() {
    // Remove duplicates
    return Array.from(new Set([...this.customerService.activePlanIds, this.plan.idAsNumber]));
  }

  get isValidPlanCombination() {
    let planIds = this.allRequiredPlanIdsWithNewPlan;
    // We need to get a single plan validation for the billing summary edit plan modal
    // If we use the ative plan and this.plan they will be invalid. i.e Start + Grow, or Starter and Support Pro
    if (this.args.showUpgradePathPlans && this.fakePlan) {
      planIds = [this.fakePlan.idAsNumber];
    }
    if (this.shouldUseQuoteService) {
      return this.quoteService.isValidPlanCombination;
    }
    return this.customerService.getPriceFromPlanIds(planIds)?.plan_combination_valid;
  }

  get shouldUseQuoteService() {
    return this.paywallService.shouldUseQuoteService(this.plan.id);
  }
  removePlanIdsFromActivePlans(planIds) {
    return this.customerService.activePlanIds.filter((planId) => {
      return !planIds.includes(planId);
    });
  }

  pricingStrategyFromPlanForMetric(plan) {
    return plan
      .get('pricingStrategies')
      .find((strategy) => strategy.pricingMetric === this.pricingMetric);
  }

  async allRequiredPriceCombinations() {
    let activePlanIds = this.customerService.activePlanIds;
    let planId = this.plan.idAsNumber;
    let siblingProductPlanIds = this.plan.product.plans.mapBy('idAsNumber');
    this.requiredAdditionalPlanIds?.forEach((planId) => {
      let requiredPlan = this.customerService.plans.find((plan) => Number(plan.id) === planId);
      siblingProductPlanIds = [
        ...siblingProductPlanIds,
        ...requiredPlan.product.plans.mapBy('idAsNumber'),
      ];
    });

    let activePlanIdsWithoutThisProduct = this.removePlanIdsFromActivePlans(siblingProductPlanIds);
    let activePlanIdsWithNewPlanId = [...activePlanIdsWithoutThisProduct, planId];

    let combinations = [activePlanIds, activePlanIdsWithNewPlanId];

    // When we display multiple plans side by side and allow upgrades/downgrades
    // we need to have each plan along with any addons or active plans on other products
    if (this.args.showUpgradePathPlans) {
      let activePlanIdsWithAllProductPlanIds = siblingProductPlanIds.map((planId) => {
        return [planId, ...activePlanIdsWithoutThisProduct];
      });
      combinations.push(...activePlanIdsWithAllProductPlanIds);
    }

    /**
     * Make another combination with
     * - Active plans
     * - New plan that is being added
     * - Additional plans required (generally for those that have pre-req's)
     */
    if (this.plan.get('requiresAdditionalPlans') && isPresent(this.requiredAdditionalPlanIds)) {
      combinations.push([...activePlanIdsWithNewPlanId, ...this.requiredAdditionalPlanIds]);
    }

    siblingProductPlanIds.forEach((planId) => combinations.push([planId]));

    let removeDuplicates = Array.from(
      new Set(combinations.map((combination) => JSON.stringify(combination.sort()))),
      JSON.parse,
    );
    return removeDuplicates.filter((combination) => combination?.length > 0);
  }

  showTrialConfirmationModal() {
    this.showUpgradeModal = false;
    this.showConfirmModal = true;
  }

  async refreshAppCustomerAndLocalCache(source, afterResolve) {
    await this.app.reload().then(() => {
      return new Promise((resolve, reject) => {
        this.customerService.loadData({ source });
        this.customerService.resetAll();
        this.app.updateLocalCache(this.modelDataCacheService);
        resolve();
        afterResolve();
      });
    });
  }

  async loadPrices() {
    if (this.shouldUseQuoteService) {
      await this.quoteService.getQuotes.perform();
    } else {
      let combinations = await this.allRequiredPriceCombinations();
      let priceSets = combinations.map((planIds) => ({
        planIds,
        includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
        source: 'paywall-component',
        includePricingByBillingPeriod: true,
        includePlanCombinationValidation: true,
      }));
      await this.customerService.bulkLoadPrices(priceSets);
    }
  }

  async loadRequiredAdditionalPlanIds() {
    try {
      let { required_plan_ids } = await post('/ember/customers/required_plans_for_upgrade', {
        app_id: this.app.id,
        requested_plan_id: this.plan.id,
      });

      this.requiredAdditionalPlanIds = required_plan_ids;
    } catch (error) {
      this.notificationsService.notifyError('Error calculating new plan dependencies');
    }
  }

  @enqueueTask
  *startContractTrial(planId) {
    let planIds = this.selfServeTrialsService.getPlanIdParamsForStartContractBasedTrial(planId);
    try {
      let params = {
        app_id: this.app.id,
        plan_ids: planIds,
      };
      yield post('/ember/customers/start_contract_based_trial', params);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'success',
        place: 'paywall',
        object: 'start_contract_based_trial',
        plan_ids: planIds,
      });
      this.args.onActionComplete?.({ success: true });
      yield this.refreshAppCustomerAndLocalCache('start-contract-trial');
      this.showTrialConfirmationModal();
    } catch (error) {
      this.purchaseAnalyticsService.trackEvent({
        action: 'failed',
        place: 'paywall',
        context: error?.jqXHR?.responseJSON?.errors ? error.jqXHR.responseJSON.errors : 'error',
        object: 'start_contract_based_trial',
        plan_ids: planIds,
      });
      this.args.onActionComplete?.({ success: false });
      this.handleError(error);
      yield this.refreshAppCustomerAndLocalCache('start-contract-trial');
    }
  }

  get isDowngrade() {
    let paywallPlan = this.product.plans.indexOf(this.fakePlan?.idAsNumber);
    let currentlyInSubscriptionPlanIndex = this.product.plans.find(
      (plan) => plan.billableCustomerPlans,
    );
    if (!currentlyInSubscriptionPlanIndex) {
      return false;
    } else {
      return paywallPlan < currentlyInSubscriptionPlanIndex;
    }
  }

  get product() {
    return this.fakePlan.product;
  }

  get productIsTrialable() {
    return this.product.trialable;
  }

  get allowTrials() {
    if (this.isDowngrade || !this.productIsTrialable) {
      return false;
    }
    return BillingConstants.MODAL_ADD_PLAN_INFO[this.fakePlan.id]?.allowTrials || true;
  }

  get currentTriallingPlanIds() {
    return this.appService.app.allPlansOnPricingModel
      .filter((plan) => plan.activeTrial && !plan.activeTrialIsGraduating)
      .mapBy('idAsNumber');
  }

  get isTrialSubscription() {
    return (
      this.currentPaidPlanIdsInSubscription.length === 0 && this.currentTriallingPlanIds.length > 0
    );
  }

  get currentPaidPlanIdsInSubscription() {
    return this.quoteService.inSubscriptionPlanIds;
  }

  get currentPlansIdsInSubscription() {
    return (
      this.appService.app.allPlansOnPricingModel
        //active will be true for non graduating trials and graduating trials
        //normally we don't want this but for the change_plan controller in billing
        //we need to send over the highest tier plan that is either paid or on trial
        .filter((plan) => plan.active)
        .mapBy('idAsNumber')
    );
  }

  // this should be moved into the paywall-service but it needs to be updated to new ember
  // Also it will need a lot of the logic that exists in here to be moved alongside it
  @dropTask *updateSubscription() {
    let planIds = [...this.currentPlansIdsInSubscription, this.fakePlan.idAsNumber];

    let object = 'upgrade';
    if (this.isDowngrade) {
      object = 'downgrade';
    } else if (this.productIsTrialable) {
      object = 'trial';
    }

    let eventData = {
      object: `paywalls-${object}-button`,
      currentPlanIds: this.currentPlansIdsInSubscription,
      paywallPlanId: this.plan?.idAsNumber,
      feature: this.featureKey,
      place: 'paywall',
    };

    try {
      if (this.isCopilot && this.customerService.isP5SelfServeAnnualCustomer) {
        yield this.annualSubscriptionUpdateService.upgrade.perform({ planIds });
      } else {
        yield post(`/ember/customers/${this.app.id}/update_subscription`, {
          plan_ids: planIds,
          app_id: this.app.id,
          allow_trials: this.allowTrials,
          source: 'paywall-component',
        });
      }
      this.purchaseAnalyticsService.trackEvent({
        ...eventData,
        action: 'update',
      });
      this.notificationsService.notifyConfirmation(
        'You have successfully updated your subscription!',
      );
      this.args.onActionComplete?.({ success: true });
      yield this.refreshAppCustomerAndLocalCache('update-subscription', this.closeUpgradeModal);
    } catch (error) {
      this.purchaseAnalyticsService.trackEvent({
        ...eventData,
        action: 'failed',
        context: error?.jqXHR?.responseJSON?.errors ? error.jqXHR.responseJSON.errors : 'error',
      });
      this.handleError(error);
      this.args.onActionComplete?.({ success: false });
      yield this.refreshAppCustomerAndLocalCache('update-subscription', this.closeUpgradeModal);
    }
  }

  handleError(error) {
    captureException(error, {
      tags: {
        responsibleTeam: 'team-purchase',
        responsible_team: 'team-purchase',
      },
    });
    if (error?.jqXHR?.responseJSON?.errors) {
      this.notificationsService.notifyError(error.jqXHR.responseJSON.errors);
    } else {
      this.notificationsService.notifyError(
        'Something went wrong. Please refresh and try again or contact support if this issue persists',
      );
    }
  }

  @dropTask *openModalAndSetData() {
    this.quoteService.setshowUpgradePathPlans(this.args.showUpgradePathPlans);

    yield this.customerService.ensureDataIsLoaded.perform();
    if (this.app.shouldDenyAccess) {
      this.trackAppIsAbuseRiskAndOpenMessenger();
      this.args.afterUpgradeModalFailedToOpen?.();
      return;
    }

    if (!this.customerService.customer.hasActiveSubscription) {
      if (this.app.validEarlyStageApplicant) {
        return this.paywallService.redirectToPurchase(this.featureKey);
      }
      yield this.stripev3.load();
    }

    // Not all paywalls know have an known associated plan
    // e.g. on a pricing model that doesn't include the plan associated with the feature
    // e.g. multiple_seat_types on any pricing model that's not VBP 2
    if (isEmpty(this.plan)) {
      this.trackUnknownPlanEventAndOpenMessenger();
      this.args.afterUpgradeModalFailedToOpen?.();
      return;
    }

    this.showUpgradeModal = true;

    // load the required additional/replacement plans for a multiplan enabled plan if subscription is editable
    if (this.plan.requiresAdditionalPlans && this.customerService.canEditCurrentSubscription) {
      yield this.loadRequiredAdditionalPlanIds();
    }

    // Fake plan and product are object proxies used to manipulate plans for billing changes
    // and not affect the actual app
    this.fakePlan = this.customerService.plans.find((plan) => plan.id === this.plan.id);
    this.quoteService.setSelectedPlanId(this.plan.idAsNumber);
    this.upgrading = this.customerService.products.find(
      (product) => product.id === this.plan.product.id,
    )?.upgrading;

    yield this.loadPrices();

    this.customerService.updateData({
      planIds: [...this.requiredPlanIdsAsStrings, this.plan.id],
    });

    this.purchaseAnalyticsService.trackEvent({
      action: 'viewed',
      context: 'paywall',
      object: 'change_plan_modal',
      ...this.analyticsEventData,
    });
  }

  @action
  setFakePlan(planId) {
    this.fakePlan = this.customerService.plans.find((plan) => plan.idAsNumber === planId);
    this.quoteService.setSelectedPlanId(planId);
    this.customerService.updateData({
      planIds: [this.fakePlan.id],
    });
    this.upgrading = this.customerService.products.find(
      (product) => product.id === this.fakePlan.product.id,
    )?.upgrading;
  }

  @action requestFreeTrial(planId) {
    let params = {
      app_id: this.app.id,
      product_name: PLAN_DATA[planId].nameWithProduct,
      admin_id: this.app.currentAdmin.id,
      admin_email: this.app.currentAdmin.email,
    };
    this.catalogApi.requestFreeTrial.perform(params);
  }

  @action openFinStandaloneUpgradeModal() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'open_modal_button',
      context: 'fin_standalone',
      place: 'paywall',
      feature: this.featureKey,
    });
    this.showFinStandaloneUpgradeModal = true;
  }

  @action closeFinStandaloneUpgradeModal() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'close_modal',
      context: 'fin_standalone',
      place: 'paywall',
      feature: this.featureKey,
    });
    this.showFinStandaloneUpgradeModal = false;
  }

  @action openUpgradeModal() {
    if (this.appService.app.isStandaloneApp) {
      this.openFinStandaloneUpgradeModal();
      return;
    }
    if (this.shouldShowCardlessConversionModal()) {
      this.toggleCardlessConversionModal();
      return;
    }
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'open_modal_button',
      context: 'unknown',
      place: 'paywall',
      feature: this.featureKey,
      planId: this.plan?.id,
      ...this.analyticsMetadata,
    });
    this.openModalAndSetData.perform();
  }

  @action closeUpgradeModal() {
    this.customerService.resetAll();
    this.showUpgradeModal = false;
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'close_modal',
      context: 'change_plan_modal',
      place: 'change_plan_modal',
      feature: this.featureKey,
      planId: this.plan?.id,
      ...this.analyticsMetadata,
    });
    if (this.args.afterCloseUpgradeModal) {
      this.args.afterCloseUpgradeModal();
    }
  }

  @tracked showConfirmModal = false;

  get trialConfirmationModalTitle() {
    if (PLAN_DATA[this.plansWithFeature.firstObject.id]?.trialConfirmationModalTitle) {
      return PLAN_DATA[this.plansWithFeature.firstObject.id].trialConfirmationModalTitle;
    }
    return this.intl.t('pricing-and-packaging.paywalls.trial_confirmation_modal.title');
  }

  get trialConfirmationModalText() {
    return PLAN_DATA[this.plansWithFeature.firstObject.id]?.trialConfirmationModalText;
  }

  get buttonLabel() {
    if (this.args.trialConfirmationAction) {
      return this.intl.t(
        'pricing-and-packaging.paywalls.trial_confirmation_modal.button_get_started',
      );
    } else {
      return this.intl.t('pricing-and-packaging.paywalls.trial_confirmation_modal.button_continue');
    }
  }

  get customFeatureHeading() {
    if (this.canTrialCorePlan && this.plan?.selfServeTrialable) {
      return this.intl.t(
        'pricing-and-packaging.paywalls.non_graduating_trial.custom_feature_heading',
        {
          featureName: this.feature?.name,
        },
      );
    }
  }

  @action
  closeTrialConfirmationModal() {
    this.trackTrialAnalyticsEvent('closed');
    this.showConfirmModal = false;
  }

  @action
  buttonAction() {
    this.showConfirmModal = false;
    this.trackTrialAnalyticsEvent('confirmed');
    if (this.args.afterCloseUpgradeModal) {
      this.args.afterCloseUpgradeModal();
    }
    if (this.args.trialConfirmationAction) {
      this.args.trialConfirmationAction();
    }
  }

  @action
  redirectToBuildYourTrial() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'redirect_to_build_trial',
      context: 'starter_no_subscription',
      place: 'paywall_modal',
      feature: this.featureKey,
      planId: this.plan?.id,
      ...this.analyticsMetadata,
    });
    this.router.transitionTo('apps.app.teams-checkout', this.appService.app.id);
  }

  trackTrialAnalyticsEvent(action) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      object: 'confirmation_modal',
      place: 'paywall_sidebar',
      context: 'scm_non_graduating_trials',
      planId: this.plan?.id,
    });
  }

  trackAppIsAbuseRiskAndOpenMessenger() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'get_feature_paywall',
      object: 'abuse_risk',
      app_id: this.appService.app.id,
      ...this.analyticsEventData,
    });

    showNewMessageInIntercomWidget(this.intl.t('paywalls.abuse-risk.open-messenger'));
  }

  trackUnknownPlanEventAndOpenMessenger() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'get_feature_paywall',
      object: 'no_known_plan',
      app_id: this.appService.app.id,
      ...this.analyticsEventData,
    });

    showNewMessageInIntercomWidget(this.intl.t('paywalls.no-known-plan.messenger-text'));
  }

  get noActiveSubOnP5() {
    return !this.customerService.customer.hasActiveSubscription && this.appService.app.onPricing5;
  }

  shouldShowCardlessConversionModal() {
    return (
      this.noActiveSubOnP5 ||
      (this.cardlessTrialService.inTrialOrExpiredTrial && !this.app.validEarlyStageApplicant)
    );
  }

  toggleCardlessConversionModal() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: this.noActiveSubOnP5 ? 'no_active_subscription' : 'cardless_trial',
      place: 'paywall',
      object: 'open_modal_button',
    });
    this.cardlessConversionModalService.toggle(this.args.afterCloseUpgradeModal);
  }
}
